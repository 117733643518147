import React, { useState, useEffect, useContext } from 'react'
import uuid from 'uuid/v1'
import cc from 'classcat'

import { colors } from '../../heydays-config'

import AppContext from '../components/context/AppContext'
import useShape from './useShape'
import Modal from './Modal'
import Video from './Video'
import BackgroundVideo from './BackgroundVideo'
import FadeIn from './FadeIn'
import AspectContainer from './AspectContainer'

const Trigger = ({ documentary, preview }) => {
  const shape = useShape(documentary.title)
  const [id, setId] = useState(null)
  const [isHovering, setIsHovering] = useState(false)
  const { state } = useContext(AppContext)
  const padding = 0
  const viewBox = shape
    ? [...shape].sort((a, b) => (a > b ? -1 : 1))[0] + padding
    : 100
  const color = documentary?.color?.hex || colors.traits

  const handleMouseEnter = e => {
    setIsHovering(true)
  }
  const handleMouseLeave = e => {
    setIsHovering(false)
  }

  useEffect(() => {
    setId(uuid())
  }, [setId])

  return (
    <div
      className={cc({
        Documentaries__inner: true,
        'Documentaries__inner--is-dark': state?.isPlaying
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <svg
        className="Documentaries__svg"
        viewBox={`-${padding} -${padding} ${viewBox + padding} ${viewBox +
          padding}`}
      >
        <rect
          className="Documentary__bg"
          width="100%"
          height="100%"
          x={-padding}
          y={-padding}
          fillOpacity="1"
          mask={`url(#Documentary-text-${id})`}
        />
        <mask key={`Documentary-mask-${id}`} id={`Documentary-text-${id}`}>
          <rect
            width="100%"
            height="100%"
            fill="#fff"
            x={-padding}
            y={-padding}
          />
          <polygon points={shape} fill="#000"></polygon>
        </mask>
        <polygon
          points={shape}
          fill={isHovering ? 'transparent' : color}
          stroke={color}
        ></polygon>
      </svg>
      {preview?.srcset && (
        <BackgroundVideo
          key="hero-movie-loop"
          className="Documentaries__video"
          video={preview?.srcset}
          controls={false}
          settings={{
            loop: true,
            muted: true,
            autoPlay: false
          }}
        />
      )}
      <div className="Documentaries__title-wrapper">
        <p className="Documentaries__title" style={{ color: color }}>
          Watch {documentary.title}
        </p>
      </div>
    </div>
  )
}

const NewDocumentary = ({ documentary, video, preview }) => {
  const { actions } = useContext(AppContext)
  if (!video || !preview) return null
  return (
    <Modal
      contentMaxWidth="80%"
      backdrop="transparent"
      animationWrapper={FadeIn}
      hideClose
      buttonText={`Watch ${documentary.title}`}
      onClick={() => actions.togglePlaying(documentary)}
      link
      triggerClassName="Documentaries__item"
      trigger={<Trigger documentary={documentary} preview={preview} />}
    >
      {({ close, isOpen }) => {
        return (
          <>
            <button
              onClick={() => {
                close()
                actions.togglePlaying(documentary)
              }}
              className="Modal__close"
            >
              close
            </button>
            <AspectContainer
              aspect={{
                sm: 'widescreen',
                md: 'widescreen',
                lg: 'widescreen',
                xl: 'widescreen',
                xxl: 'widescreen'
              }}
              className="Documentaries__video-backdrop"
            >
              {isOpen && video?.srcset && (
                <Video
                  key="hero-movie-loop"
                  video={video?.srcset}
                  controls={true}
                  settings={{
                    loop: false,
                    muted: false,
                    autoPlay: true
                  }}
                />
              )}
            </AspectContainer>
          </>
        )
      }}
    </Modal>
  )
}

export default NewDocumentary
