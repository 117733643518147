export const letters = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  'æ',
  'ø',
  'å',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9'
]

export const getLetterIndex = letter => {
  const index = letters.findIndex(item => item === letter.toLowerCase())
  return index >= 0 && index + 1
}

export const getIndexFromName = name =>
  name.split('').reduce((sum, letter) => {
    return sum + getLetterIndex(letter)
  }, 0)

export const getColorFromNumber = name => {
  const multiplier = 2
  const shift = 0
  return `hsl(
    ${parseInt(
      (360 / (letters.length * multiplier) + shift) * getIndexFromName(name)
    )},
    50%,
    50%
)`
}

export const generateStar = (name, overrideRadius = 1, highest = false) => {
  const innerRadius = (name % 40) + 60 + overrideRadius * 2
  const outerRadius = (name % 120) + 60 + overrideRadius
  const numPoints = Math.max(name % 30, 3)
  const center = Math.max(innerRadius, outerRadius)
  const angle = Math.PI / numPoints
  const points = []

  for (let i = 0; i < numPoints * 2; i++) {
    const radius = i & 1 ? innerRadius : outerRadius
    points.push(center + radius * Math.sin(i * angle))
    points.push(center - radius * Math.cos(i * angle))
  }

  return highest ? points.sort((a, b) => (a > b ? -1 : 1))[0] : points
}
