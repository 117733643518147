import React, { useEffect, useState, useRef } from 'react'
import useWindowSize from './hooks/useWindowSize'
import VisibilitySensor from 'react-visibility-sensor'
import PageVisibility from 'react-page-visibility'
import cc from 'classcat'

const Video = props => {
  const viewport = useRef(null)
  const video = useRef(null)
  const [videoSource, setVideoSource] = useState({})
  const [showPlay, setShowPlay] = useState(false)

  const windowSize = useWindowSize({ debounce: 250 })

  const play = () => {
    if (video && video.current) {
      const promise = video.current.play()
      if (promise !== undefined) {
        promise
          .catch(error => {
            setShowPlay(true)
          })
          .then(() => {
            // Auto-play started
            setShowPlay(false)
          })
      }
    }
  }

  const visibilityCheck = isVisible => {
    if (video && video.current) {
      if (isVisible) {
        // play()
      } else {
        video.current.pause()
      }
    }
  }

  const setFullScreen = elem => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen()
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen()
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen()
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen()
    }
  }

  useEffect(() => {
    const getVideo = video => {
      if (viewport && viewport.current) {
        const { width } = viewport.current.getBoundingClientRect()

        // Remove videos without size attributes
        const videoSizesClean = video.filter(vid => vid.width)

        // Get the actual container resolution
        const realWidth = window.devicePixelRatio
          ? width * window.devicePixelRatio
          : width

        // Order by smallest first
        videoSizesClean.sort(function(a, b) {
          return a.width - b.width
        })

        // Get the video closest to viewport reolution
        for (let i = 0; i < videoSizesClean.length; i++) {
          const video = videoSizesClean[i]
          if (video.width && video.width >= realWidth) {
            return video
          }
        }

        // Return the largest video if nothing matches
        return videoSizesClean[videoSizesClean.length - 1]
      }
    }

    // Is needed to actually switch the video
    // changing the src alone does not work
    const switchVideoSource = () => {
      if (video && video.current) {
        const progress = video.current.currentTime
        video.current.pause()
        video.current.load()
        video.current.currentTime = progress
        video.current.play()
      }
    }

    const newVideoSrc = getVideo(props.video)
    if (newVideoSrc !== videoSource) {
      setVideoSource(newVideoSrc)
      switchVideoSource()
    }
  }, [windowSize, props.video, videoSource])

  /**
   * Add Pause prop
   * pauseMovie={someTogglingState}
   */
  useEffect(() => {
    if (video?.current) {
      if (props.pauseMovie) {
        video.current.pause()
      } else {
        video.current.play()
      }
    }
  }, [props.pauseMovie, video])

  // viewport must be rendered to get the correct video size
  return (
    <PageVisibility onChange={visibilityCheck}>
      <VisibilitySensor
        active={videoSource.link ? true : false}
        onChange={visibilityCheck}
        partialVisibility
      >
        <div
          ref={viewport}
          className={cc({
            Video: true,
            [props.className]: props.className
          })}
        >
          <div className="Video__container">
            {videoSource.link && (
              <video
                width={videoSource.width}
                height={videoSource.height}
                className="Video__video"
                ref={video}
                controls={props.controls}
                {...props.settings}
                // onEnded={props.onEnded}
                // onPaused={props.onPaused}
              >
                <source src={videoSource.link} type={videoSource.type} />
                <p>Your browser does not support the video tag.</p>
              </video>
            )}
            {showPlay && (
              <button className="Video__play" onClick={play}>
                play
              </button>
            )}
            {props.fullScreen && (
              <button
                className="Video__fullscreen"
                onClick={() => setFullScreen(video.current)}
              >
                fullscreen
              </button>
            )}
          </div>
        </div>
      </VisibilitySensor>
    </PageVisibility>
  )
}

export default Video
