import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import FrontPage from '../components/pages/FrontPage'

const Index = props => {
  const { data } = props
  const page = data?.sanitySiteSettings?.frontpage
  const documentaries = data?.allSanityDocumentary?.nodes
  return (
    <Layout noHeader {...page}>
      <FrontPage {...page} documentaries={documentaries} />
    </Layout>
  )
}

export default Index

export const query = graphql`
  {
    sanitySiteSettings(id: { eq: "0f217bb5-f7f6-5420-b7c6-58db2c12b8c7" }) {
      frontpage {
        ...Page
      }
    }
    allSanityDocumentary {
      nodes {
        id
        color {
          hex
        }
        film
        preview
        title
      }
    }
  }
`
