import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import cc from 'classcat'

import NewDocumentary from './NewDocumentary'

const Documentaries = ({ documentaries }) => {
  const {
    allVimeo: { nodes: videos }
  } = useStaticQuery(query)
  return (
    <div className="Documentaries">
      <h3 className="Documentaries__heading">Documentaries</h3>
      <div
        className={cc({
          Documentaries__content: true,
          'Documentaries__content--break': true //documentaries.length > 3
        })}
      >
        {documentaries &&
          Array.isArray(documentaries) &&
          documentaries.map(docu => {
            const video = videos.filter(video => video.link === docu.film)
            const preview = videos.filter(video => video.link === docu.preview)
            return (
              video && (
                <NewDocumentary
                  key={docu.id}
                  documentary={docu}
                  video={video && video[0]}
                  preview={preview && preview[0]}
                />
              )
            )
          })}
      </div>
    </div>
  )
}

export default Documentaries

export const query = graphql`
  {
    allVimeo {
      nodes {
        link
        aspectRatio
        description
        duration
        id
        name
        srcset {
          ...GatsbyVimeoSrcSet
        }
      }
    }
  }
`
