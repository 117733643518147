import React from 'react'
import useShape from './useShape'
import Section from './Section'
import Container from './Container'

import { colors } from '../../heydays-config'

const HeroShape = () => {
  const traits = useShape('Traits')
  const traitsViewBox = traits
    ? [...traits].sort((a, b) => (a > b ? -1 : 1))[0]
    : 100
  const svgStyle = {
    width: '1em',
    height: '1em'
  }
  return (
    <div className="Hero Hero--shape">
      <Container>
        <Section>
          <h2>
            Traits{' '}
            <svg
              className="animation-rotate"
              style={svgStyle}
              viewBox={`0 0 ${traitsViewBox} ${traitsViewBox}`}
              fill={colors.traits}
            >
              <polygon points={traits}></polygon>
            </svg>{' '}
            is a unique storytelling platform for exceptional musical talent. We
            showcase a diverse cast of creative minds.
          </h2>
        </Section>
      </Container>
    </div>
  )
}

export default HeroShape
