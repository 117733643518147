import React, { useEffect, useState, useRef } from 'react'
import useWindowSize from './hooks/useWindowSize'
import VisibilitySensor from 'react-visibility-sensor'
import PageVisibility from 'react-page-visibility'
import cc from 'classcat'

const BackgroundVideo = props => {
  const viewport = useRef(null)
  const video = useRef(null)
  const [videoSource, setVideoSource] = useState({})

  const windowSize = useWindowSize({ debounce: 250 })

  const visibilityCheck = isVisible => {
    if (video && video.current) {
      if (isVisible) {
        video.current.play()
      } else {
        video.current.pause()
      }
    }
  }

  useEffect(() => {
    const getVideo = video => {
      if (viewport && viewport.current) {
        const { width } = viewport.current.getBoundingClientRect()

        // Remove videos without size attributes
        const videoSizesClean = props.video.filter(vid => vid.width)

        // Get the actual container resolution
        const realWidth = window.devicePixelRatio
          ? width * window.devicePixelRatio
          : width

        // Order by smallest first
        videoSizesClean.sort(function(a, b) {
          return a.width - b.width
        })

        // Get the video closest to viewport reolution
        for (let i = 0; i < videoSizesClean.length; i++) {
          const video = videoSizesClean[i]
          if (video.width && video.width >= realWidth) {
            return video
          }
        }

        // Return the largest video if nothing matches
        return videoSizesClean[videoSizesClean.length - 1]
      }
    }
    setVideoSource(getVideo(props.video))
  }, [windowSize, props.video])

  useEffect(() => {
    const handleResize = () => {
      if (viewport && viewport.current && video && video.current) {
        const { width, height } = viewport.current.getBoundingClientRect()

        let origWidth = 1920
        let origHeight = 732

        if (videoSource && videoSource.width && videoSource.height) {
          origWidth = videoSource.width
          origHeight = videoSource.height
        }

        const scaleHorizontal = width / origWidth
        const scaleVertical = height / origHeight
        const scale =
          scaleHorizontal > scaleVertical ? scaleHorizontal : scaleVertical

        const newWidth = scale * origWidth
        const newHeight = scale * origHeight

        video.current.width = newWidth
        video.current.height = newHeight

        video.current.style.left = `${Math.round((width - newWidth) / 2)}px`
        video.current.style.top = `${Math.round((height - newHeight) / 2)}px`
      }
    }
    const timeout = setTimeout(() => {
      handleResize()
    }, 100)
    return () => {
      clearTimeout(timeout)
    }
  }, [windowSize, viewport, videoSource, props.video, video])

  // viewport must be rendered to get the correct video size
  return (
    <PageVisibility onChange={visibilityCheck}>
      <VisibilitySensor
        active={videoSource.link ? true : false}
        onChange={visibilityCheck}
        partialVisibility
      >
        <div
          ref={viewport}
          className={cc({
            BackgroundVideo: true,
            [props.className]: props.className
          })}
        >
          <div className="BackgroundVideo__container">
            {videoSource.link && (
              <video
                playsInline="playsinline"
                width={videoSource.width}
                height={videoSource.height}
                className="BackgroundVideo__video"
                ref={video}
                {...props.settings}
                // onEnded={props.onEnded}
                // onPaused={props.onPaused}
              >
                <source src={videoSource.link} type={videoSource.type} />
                <p>Your browser does not support the video tag.</p>
              </video>
            )}
          </div>
        </div>
      </VisibilitySensor>
    </PageVisibility>
  )
}

export default BackgroundVideo
