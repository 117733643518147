import { generateStar, getIndexFromName } from '../utils/generators'
import { useState, useEffect } from 'react'

const useShape = (name, radius) => {
  const [points, setPoints] = useState()
  const nameIndex = getIndexFromName(name)

  useEffect(() => {
    setPoints(generateStar(nameIndex, radius))
  }, [radius, nameIndex])
  return points
}

export default useShape
