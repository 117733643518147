import React from 'react'

import Pagebuilder from '../pagebuilder/Pagebuilder'
import Documentaries from '../Documentaries'
import Container from '../Container'
import HeroShape from '../HeroShape'

const FrontPage = ({ _rawPagebuilder, documentaries }) => {
  return (
    <>
      <h1 className="visually-hidden">Traits</h1>
      <HeroShape />
      <Documentaries documentaries={documentaries} />
      <Container>
        {_rawPagebuilder?.sections && (
          <Pagebuilder sections={_rawPagebuilder.sections} />
        )}
      </Container>
    </>
  )
}

export default FrontPage
